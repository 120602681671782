import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger.js'
gsap.registerPlugin(ScrollTrigger)

const dissect = () => {
    const mm = gsap.matchMedia()

    const isDesktop = '(min-width: 768px)'

    mm.add(isDesktop, () => {
        const dissectTimeline = gsap.timeline({
            scrollTrigger: {
                trigger: '.dissect',
                start: 'top top',
                end: '500%',
                scrub: true,
                pin: '.dissect',
                snap: {
                    snapTo: 'labels',
                    duration: { min: 0.2, max: 0.4 },
                    ease: 'power1.inOut',
                },
            },
        })

        const paragraphs = document.querySelectorAll('.dissect__paragraph')

        paragraphs.forEach((paragraph, i) => {
            dissectTimeline
                .fromTo(
                    paragraph,
                    { opacity: 0, y: '20vh' },
                    { opacity: 1, y: '0vh' }
                )
                .addLabel(`snap-point${i}`)
                .fromTo(
                    i !== paragraphs.length - 1 ? paragraph : null,
                    { opacity: 1, y: '0' },
                    { opacity: 0, y: '-20vh' }
                )
        })
    })
}

export default dissect
