import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger.js'
gsap.registerPlugin(ScrollTrigger)

const imageFade = () => {
    const sliderWrapper = document.querySelector('.slider__container')
    const mm = gsap.matchMedia()

    const isDesktop = '(min-width: 768px)'

    mm.add(isDesktop, () => {
        // VERTICALLY SCROLLING IMAGE BLOCK TIMELINE
        const imageBlocks = document.querySelectorAll('.imageFade__animate')
        const textBlocks = document.querySelectorAll(
            '.imageFade__text-under-header'
        )

        const imageStackTimeline = gsap.timeline({
            scrollTrigger: {
                trigger: '.imageFade__container',
                anticipatePin: 1,
                start: 'top top',
                end: '300%',
                scrub: true,
                snap: {
                    snapTo: 'labels',
                    duration: { min: 0.2, max: 0.4 },
                    ease: 'power1.inOut',
                },
                pin: '.imageFade__container',
            },
        })

        imageBlocks.forEach((image, i) => {
            imageStackTimeline
                .fromTo(
                    image,
                    { opacity: 0, y: '20vh' },
                    { opacity: 1, y: '0vh' }
                )
                .to(textBlocks[i], { opacity: 1 }, '<')
                .addLabel(`snap-point${i}`)
            if (i < imageBlocks.length - 1) {
                imageStackTimeline
                    .fromTo(
                        image,
                        { opacity: 1, y: '0' },
                        { opacity: 0, y: '-20vh' }
                    )
                    .to(textBlocks[i], { opacity: 0 }, '<')
            }
        })
    })
}

export default imageFade
