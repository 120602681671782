import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger.js'
gsap.registerPlugin(ScrollTrigger)

const horizontalScroller = () => {
    const sliderWrapper = document.querySelector('.slider__container')
    const mm = gsap.matchMedia()

    const isDesktop = '(min-width: 768px)'

    mm.add(isDesktop, () => {
        gsap.to('.slider__wrapper', {
            scrollTrigger: {
                trigger: '.slider',
                start: () => 'top top',
                end: '300%',
                pin: true,
                scrub: true,
            },
            x: -sliderWrapper.scrollWidth + window.innerWidth - 100,
        })
    })
}

export default horizontalScroller
