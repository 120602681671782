import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger.js'
gsap.registerPlugin(ScrollTrigger)

const landingHero = () => {
    const images = gsap.utils.toArray('.landingHero__img-container')
    const landingHero = document.querySelectorAll('.landingHero__header')
    const landingBtn = document.querySelectorAll('.landingHero__btn')
    const imagesCopy = images.slice(0)
    const shuffledImages = imagesCopy.sort((a, b) => 0.5 - Math.random())

    const mm = gsap.matchMedia()

    const isDesktop = '(min-width: 1024px)'

    const heroTl = gsap.timeline({
        delay: 0.7,
    })

    heroTl.to(
        shuffledImages,
        {
            opacity: 1,
            stagger: 0.07,
            duration: 1,
            ease: 'power4.easeOut',
        },
        '>-.5'
    )
    heroTl.to(
        landingHero,
        {
            opacity: 1,
            duration: 1.5,
            ease: 'power4.easeOut',
        },
        '>-.2'
    )
    heroTl.to(
        landingBtn,
        {
            opacity: 1,
            duration: 1.5,
            ease: 'power4.easeOut',
        },
        '>-.9'
    )

    // SCROLLTRIGGER ANIMATION
    const stOptions = [
        { x: 0, y: '-20vh' },
        { x: '12vw', y: '8vh' },
        { x: '10vw', y: '16vh' },
        { x: '21vw', y: '-5vh' },
        { x: '19vw', y: '-1vh' },
        { x: '20vw', y: '7vh' },

        { x: '-26vw', y: '-7vh' },
        { x: '-26vw', y: '7vh' },
        { x: '-4vw', y: '-14vh' },
        { x: '-22vw', y: '-2vh' },
        { x: '-18vw', y: '5vh' },
    ]

    mm.add(isDesktop, () => {
        setTimeout(() => {
            gsap.fromTo(
                images,
                {
                    opacity: 1,
                },
                {
                    opacity: 0,
                    x: i => stOptions[i].x,
                    y: i => stOptions[i].y,
                    scrollTrigger: {
                        trigger: '.landingHero',
                        start: 'top top',
                        end: '150%',
                        scrub: 1,
                        immediateRender: false,
                        ease: 'power4.easeOut',
                    },
                }
            )
        }, 1540)
    })
}

export default landingHero
