import gsap from 'gsap'

const mobileNav = () => {
    const openMobileNavBtn = document.querySelector('.nav__menu')
    const closeMobileNavBtn = document.querySelector('.close-nav')
    const mobileNav = document.querySelector('.nav__container')
    openMobileNavBtn.addEventListener('click', () => {
        gsap.to(mobileNav, {
            zIndex: '999999',
            opacity: '1',
            ease: 'power4.out',
            duration: 0.6,
        })
    })
    closeMobileNavBtn.addEventListener('click', () => {
        console.log("click")
        gsap.to(mobileNav, {
            zIndex: '0',
            opacity: '0',
            ease: 'power4.out',
            duration: 0.6,
        })
    })
}

export default mobileNav
