import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger.js'
gsap.registerPlugin(ScrollTrigger)

const imageAnimate = () => {
    const sliderWrapper = document.querySelector('.slider__container')
    const mm = gsap.matchMedia()

    const isDesktop = '(min-width: 768px)'

    mm.add(isDesktop, () => {
        const imageBlocks = document.querySelectorAll('.imageAnimate__animate')

        const imageAnimateTimeline = gsap.timeline({
            scrollTrigger: {
                trigger: '.imageAnimate',
                start: 'center center',
                end: '300%',
                scrub: true,
                snap: {
                    snapTo: 'labels',
                    duration: { min: 0.2, max: 0.4 },
                    ease: 'power1.inOut',
                },
                pin: '.imageAnimate',
            },
        })

        imageBlocks.forEach((image, i) => {
            imageAnimateTimeline
                .fromTo(
                    image,
                    { opacity: 0, y: '20vh' },
                    { opacity: 1, y: '0vh' },
                )
                .addLabel(`snap-point${i}`)
                .fromTo(
                    i !== imageBlocks.length - 1 ? image : null,
                    { opacity: 1, y: '0' },
                    { opacity: 0, y: '-20vh' }
                )
        })
    })
}

export default imageAnimate
