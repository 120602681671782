import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger.js'
gsap.registerPlugin(ScrollTrigger)

const video = () => {
    const mm = gsap.matchMedia()

    const isDesktop = '(min-width: 768px)'
    const isMobile = '(max-width: 767px)'

    const video = document.querySelector('.video-background')
    let src = video.currentSrc || video.src

    /* Make sure the video is 'activated' on iOS */
    function once(el, event, fn, opts) {
        var onceFn = function (e) {
            el.removeEventListener(event, onceFn)
            fn.apply(this, arguments)
        }
        el.addEventListener(event, onceFn, opts)
        return onceFn
    }

    // Ryan solution to mobile
    let videoEl = document.getElementById('scroll-video'),
        forcePause = () => {
            videoEl.removeAttribute('autoplay')
            videoEl.currentTime = 0
            videoEl.pause()
        }

    videoEl.load()

    videoEl.onloadeddata = forcePause()

    mm.add(isDesktop, () => {
        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: '.video__container',
                start: 'top top',
                end: '400%',
                scrub: 1,
                pin: '.video__container',
            },
        })

        once(video, 'loadedmetadata', () => {
            tl.to(video, { currentTime: 18 })
                .to(
                    '.video__video',
                    { height: '70%', width: '70%', borderRadius: '10px' },
                    '<'
                )
                .to(video, { opacity: 0.5 }, '-=70%')
                .to('.video__text', { opacity: 1, ease: 'power4.in' }, '-=90%')
        })
    })

    mm.add(isMobile, () => {
        let mobileTl = gsap.timeline({
            scrollTrigger: {
                trigger: '.video__container',
                start: 'bottom bottom',
                end: '100%',
                scrub: 1,
            },
        })
        once(video, 'loadedmetadata', () => {
            mobileTl
                .to(video, { currentTime: 18 })
                .to(video, { opacity: 0.5 }, '-=50%')
                .to('.video__text', { opacity: 1 }, '-=50%')
        })
    })
}

export default video
