import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger.js'
gsap.registerPlugin(ScrollTrigger)

const oceanscoutHero = () => {
    const mm = gsap.matchMedia()

    const isDesktop = '(min-width: 768px)'

    mm.add(isDesktop, () => {
        gsap.to('.oceanscoutHero', {
            scrollTrigger: {
                trigger: '.oceanscoutHero',
                start: 'top top',
                end: '',
                scrub: 1,
            },
            backgroundPositionY: '-50vh',
        })
    })
}

export default oceanscoutHero
