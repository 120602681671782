import oceanscoutHero from './animations/oceanscoutHero'
import dissect from './animations/dissect'
import imageFade from './animations/imageFade'
import horizontalScroller from './animations/horizontalScroller'
import scrollFade from './animations/scrollFade'
import slider from './animations/slider'
import video from './animations/video'
import clickShow from './animations/clickShow'
import mobileNav from './nav/mobileNav'
import entrySlider from './animations/entrySlider'
import imageAnimate from './animations/imageAnimate'
import landingHero from './animations/landingHero'
import navAnimate from './animations/nav'

const pageUrl = window.location.pathname

// Activate Imported modules
navAnimate()
mobileNav()
// load JS for specific pages

if (pageUrl == '/oceanscout') {
    oceanscoutHero()
    video()
    scrollFade()
    imageFade()
    dissect()
    horizontalScroller()
    slider()
    clickShow()
} else if (pageUrl == '/') {
    landingHero()
    // imageAnimate()
    entrySlider()
}
