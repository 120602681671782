const clickShow = () => {
    let textBlocks = document.querySelectorAll('.clickSwitch__block')

    let numberMatch

    textBlocks.forEach(block => {
        block.addEventListener('click', e => {
            if (!e.target.closest('div').classList.contains('active-block')) {
                numberMatch = e.target.closest('div').id

                document
                    .querySelector('.active-block')
                    .classList.remove('active-block')

                e.target.closest('div').classList.add('active-block')

                document
                    .querySelector('.active-img')
                    .classList.remove('active-img')

                document
                    .getElementById(`img-${numberMatch}`)
                    .classList.add('active-img')
            }
        })
    })
}

export default clickShow
