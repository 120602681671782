import Swiper, { Navigation } from 'swiper'
// import Swiper styles
import 'swiper/css'

Swiper.use([Navigation])

const slider = () => {
    const swiper = new Swiper('.swiper-slider', {
        // Optional parameters
        centeredSlides: true,
        // slidesPerView: 1,
        grabCursor: true,
        freeMode: false,
        mousewheel: false,
        keyboard: {
            enabled: true,
        },

        // If we need pagination
        pagination: {
            el: '.swiper-pagination',
            dynamicBullets: false,
            clickable: true,
        },

        // If we need navigation
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },

        // Responsive breakpoints
        breakpoints: {
            640: {
                slidesPerView: 1,
                spaceBetween: 20,
            },
            1024: {
                slidesPerView: 1,
                spaceBetween: 20,
            },
        },
    })
}

export default slider
