import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger.js'
gsap.registerPlugin(ScrollTrigger)

const navAnimate = () => {
    const nav = document.querySelector('.nav')
    const mm = gsap.matchMedia()

    const isDesktop = '(min-width: 768px)'

    mm.add(isDesktop, () => {
        gsap.to(nav, { opacity: 1, duration: .7 })
    })
}

export default navAnimate
