import Swiper, { Navigation } from 'swiper'
// import Swiper styles
import 'swiper/css'

Swiper.use([Navigation])

const entrySlider = () => {
    const nextBtn = document.querySelector(".swiper-button-next")
    const prevBtn = document.querySelector(".swiper-button-prev")

    const swiper = new Swiper('.swiper-slider', {
        // Optional parameters
        centeredSlides: false,
        slidesPerView: 2.1,
        spaceBetween: 50,
        grabCursor: true,
        freeMode: false,
        loop: false,
        mousewheel: false,
        keyboard: {
            enabled: true,
        },

        // If we need pagination
        pagination: {
            el: '.swiper-pagination',
            dynamicBullets: false,
            clickable: true,
        },

        // If we need navigation
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            0: {
                slidesPerView: 1.1,
            },
            768: {
                slidesPerView: 2.1,
            },
        },

        on: {
            slideChange: function () {
                if (this.isEnd) {
                    nextBtn.classList.add("disabled")
                } else {
                    nextBtn.classList.remove("disabled")
                }

                if (this.isBeginning) {
                    prevBtn.classList.add("disabled")
                } else {
                    prevBtn.classList.remove("disabled")
                }
            },
        },
    })
}

export default entrySlider
