import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger.js'
gsap.registerPlugin(ScrollTrigger)

const scrollFade = () => {
    const mm = gsap.matchMedia()

    const isDesktop = '(min-width: 768px)'

    mm.add(isDesktop, () => {
        const paragraphs = gsap.utils.toArray('.scrollFade__paragraph')
        const gliders = gsap.utils.toArray('.scrollFade__img-container')

        const pinTimeline = gsap.timeline({
            scrollTrigger: {
                trigger: '.scrollFade',
                start: 'top top',
                end: '+=300%',
                scrub: true,
                pin: '.scrollFade',
                snap: {
                    snapTo: 'labels',
                    duration: { min: 0.2, max: 0.7 },
                    ease: 'power1.inOut',
                },
            },
        })

        paragraphs.forEach((paragraph, i) => {
            pinTimeline
                .fromTo(
                    paragraph,
                    { opacity: 0, y: '20vh' },
                    { opacity: 1, y: '0vh' }
                )
                .to(gliders[i], { opacity: 1 }, '<')
                .addLabel(`snap-point${i}`)
            if (i < paragraphs.length - 1) {
                pinTimeline
                    .fromTo(
                        paragraph,
                        { opacity: 1, y: '0' },
                        { opacity: 0, y: '-20vh' }
                    )
                    .to(gliders[i], { opacity: 0 }, '<')
            }
        })
    })
}

export default scrollFade
